import axios from 'axios';
import authHeader from './auth-header';
import * as settings from '../config/settings';
// import { nlNL } from '@mui/x-date-pickers';

const API_URL = `${settings.APIBASEURL}/`;

const getAllTeamPlayers = (req) => {
    return axios.post(API_URL + 'team_player/findall', req, { headers: authHeader() }).then((response) => {
        console.log('getAllTeamPlayers', response.data);
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getGameTeamPlayers = (req) => {
    return axios.post(API_URL + 'team_player/playersbygameteam', req, { headers: authHeader() }).then((response) => {
        console.log('getGameTeamPlayers', response.data);
        return response.data;
    });
};

const getAllGameTeamPlayers = (gameId) => {
    return axios.get(API_URL + `team_player/allplayersbygameteam/${gameId}`, { headers: authHeader(), data: { gameId } }).then((response) => {
        console.log('getAllGameTeamPlayers', response.data);
        return response.data;
    });
};

const getGameTeamPlayersByTeam = (teamId, gameId) => {
    //Fixed
    return axios.get(API_URL + `team_player/playersbyteam/${teamId}/${gameId}`, { headers: authHeader(), data: { teamId, gameId } }).then((response) => {
        response.data.forEach((game) => {
            if (game.image) game.image = process.env.REACT_APP_S3_URI + game.image;
        });
        return response.data;
    });
};
const addTeamPlayer = (req) => {
    return axios.post(API_URL + 'team_player/create', req, { headers: authHeader() }).then((response) => {
        console.log('addTeamPlayer', response.data);
        return response.data;
    });
};

const addTeam = (req) => {
    return axios.post(API_URL + 'team', req, { headers: authHeader() }).then((response) => {
        console.log('addTeam', response.data);
        return response.data;
    });
};

const addMultiple = (req) => {
    return axios.post(API_URL + 'multiple', req, { headers: authHeader() }).then((response) => {
        console.log('addMultiple', response.data);
        return response.data;
    });
};

const getAllMultipleData = () => {
    return axios.get(API_URL + 'multiple', { headers: authHeader() }).then((response) => {
        console.log('getAllMultipleData', response.data);
        return response.data;
    });
};

const getAllMultipleDataByIds = (ids) => {
    return axios.get(API_URL + `multiple/get/${ids}`, { headers: authHeader(), data: { ids } }).then((response) => {
        console.log('getAllMultipleDataByIds', response.data);
        return response.data;
    });
};

const deleteMultipleDataByIds = (ids) => {
    return axios.delete(API_URL + `multiple/delete/${ids}`, { headers: authHeader(), data: { ids } }).then((response) => {
        console.log('deleteMultipleDataByIds', response.data);
        return response.data;
    });
};

const deleteAllMultiple = () => {
    return axios.delete(API_URL + 'multiple', { headers: authHeader() }).then((response) => {
        console.log('deleteAllMultiple', response.data);
        return response.data;
    });
};

const getAllTeams = () => {
    //Fixed
    return axios.get(API_URL + 'team', { headers: authHeader() }).then((response) => {
        response.data.forEach((team) => {
            if (team.image) team.image = process.env.REACT_APP_S3_URI + team.image;
            if (team.sponsor_logo) team.sponsor_logo = process.env.REACT_APP_S3_URI + team.sponsor_logo;
        });
        return response.data;
    });
};

const getAllCoachTeam = () => {
    //Fixed
    return axios.get(API_URL + 'coach_team', { headers: authHeader() }).then((response) => {
        console.log('getAllCoachTeam', response.data);
        return response.data;
    });
};

const getAllGamesByCoach = (seasonId, leagueId, teamId, datesBack) => {
    //Fixed
    return axios.get(API_URL + `game/getbycoach/${seasonId}/${leagueId}/${teamId}/${datesBack}`, { headers: authHeader(), data: { seasonId, leagueId, teamId, datesBack } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_image) game.away_team_image = process.env.REACT_APP_S3_URI + game.away_team_image;
            if (game.home_team_image) game.home_team_image = process.env.REACT_APP_S3_URI + game.home_team_image;
            if (game.image) game.image = process.env.REACT_APP_S3_URI + game.image;
            if (game.away_team_standing_image) game.away_team_standing_image = process.env.REACT_APP_S3_URI + game.away_team_standing_image;
            if (game.home_team_standing_image) game.home_team_standing_image = process.env.REACT_APP_S3_URI + game.home_team_standing_image;
        });
        return response.data;
    });
};

const getAdditionalGames = (seasonId, leagueId, teamId, datesBack) => {
    return axios.get(API_URL + `game/getadditional/${seasonId}/${leagueId}/${teamId}/${datesBack}`, { headers: authHeader(), data: { seasonId, leagueId, teamId, datesBack } }).then((response) => {
        console.log('getAdditionalGames', response.data);
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
            if (game.image) game.image = process.env.REACT_APP_S3_URI + game.image;
        });
        return response.data;
    });
};

const getCleanGame = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getcleangame/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamGoals = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamgoals/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentGoals = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentgoals/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getGamesByIds = (gameIds) => {
    return axios.get(API_URL + `game/all/${gameIds}`, { headers: authHeader(), data: { gameIds } }).then((response) => {
        console.log('getGamesByIds', response.data);
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamGoalOpportunity = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamgoalopportunity/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentGoalOpportunity = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentgoalopportunity/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamOffensivePossession = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamoffensivepossession/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentOffensivePossession = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentoffensivepossession/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamDefensivePossession = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamdefensivepossession/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentDefensivePossession = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentdefensivepossession/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamBuildupGoalkeeper = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteambuildupgoalkeeper/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentBuildupGoalkeeper = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentbuildupgoalkeeper/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamBuildupGoalkeeperKick = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteambuildupgoalkeeperkick/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentBuildupGoalkeeperKick = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentbuildupgoalkeeperkick/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamBuildonDefensiveHalf = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteambuildondefensivehalf/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentBuildonDefensiveHalf = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentbuildondefensivehalf/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamInterception = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteaminterception/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentInterception = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentinterception/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamTackle = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamtackle/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentTackle = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponenttackle/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamThrowIn = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamthrowin/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentThrowIn = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentthrowin/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamFreekick = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamfreekick/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentFreekick = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentfreekick/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        return response.data;
    });
};

const getTeamCorner = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamcorner/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentCorner = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentcorner/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamCross = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamcross/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentCross = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentcross/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamPenalty = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteampenalty/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentPenalty = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentpenalty/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamDrawfoul = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamdrawfoul/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentDrawfoul = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentdrawfoul/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamOffside = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamoffside/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentOffside = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentoffside/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamShots = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamshots/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentShots = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentshots/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getGameHighlight = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getgamehighlight/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamBuildOnOffensiveHalf = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteambuildonoffensivehalf/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentBuildOnOffensiveHalf = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentbuildonoffensivehalf/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamCounterAttack = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamcounterattack/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentCounterAttack = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentcounterattack/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamTurnover = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamturnover/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentTurnover = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentturnover/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamSaved = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamsaved/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentSaved = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentsaved/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamClearance = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamclearance/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentClearance = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentclearance/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getTeamBlocked = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getteamblocked/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getOpponentBlocked = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/getopponentblocked/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const gameExportSportcode = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/game_export_sportcode/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const gameExportSportcodeShort = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `game/game_export_sportcode_short/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getGameById = (gameId) => {
    //Fixed
    return axios.get(API_URL + `game/getgamebyid/${gameId}`, { headers: authHeader(), data: { gameId } }).then((response) => {
        if (response.data.away_team_image) response.data.away_team_image = process.env.REACT_APP_S3_URI + response.data.away_team_image;
        if (response.data.home_team_image) response.data.home_team_image = process.env.REACT_APP_S3_URI + response.data.home_team_image;
        if (response.data.image) response.data.image = process.env.REACT_APP_S3_URI + response.data.image;
        return response.data;
    });
};

const getAllPlayersByCoach = () => {
    //Fixed
    return axios.get(API_URL + `coach_team/getAllPlayersByCoach`, { headers: authHeader() }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getAllLeaguesByCoach = (userId) => {
    return axios.get(API_URL + `coach_team/getAllLeaguesByCoach/${userId}`, { headers: authHeader(), data: { userId } }).then((response) => {
        console.log('getAllLeaguesByCoach', response.data);
        return response.data;
    });
};
const getAllTeamsByCoach = (userId) => {
    return axios.get(API_URL + `coach_team/getAllTeamsByCoach/${userId}`, { headers: authHeader(), data: { userId } }).then((response) => {
        console.log('getAllTeamsByCoach', response.data);
        return response.data;
    });
};
const getAllTeamsByLeagueSeason = (userId, seasonId, leagueId) => {
    return axios.get(API_URL + `coach_team/getAllTeamsByLeagueSeason/${userId}/${seasonId}/${leagueId}`, { headers: authHeader(), data: { userId, seasonId, leagueId } }).then((response) => {
        console.log('getAllTeamsByLeagueSeason', response.data);
        return response.data;
    });
};

const getAllLeaguesOfAdditionalGamesByCoach = () => {
    return axios.get(API_URL + `coach_team/getAllLeaguesOfAdditionalGamesByCoach`, { headers: authHeader() }).then((response) => {
        console.log('getAllLeaguesOfAdditionalGamesByCoach', response.data);
        return response.data;
    });
};

const getAllTeamsOfAdditionalGamesByCoach = () => {
    return axios.get(API_URL + `coach_team/getAllTeamsOfAdditionalGamesByCoach`, { headers: authHeader() }).then((response) => {
        console.log('getAllTeamsOfAdditionalGamesByCoach', response.data);
        return response.data;
    });
};

const getNumberOfGamesOrdered = () => {
    return axios.get(API_URL + `coach_team/getNumberOfGamesOrdered`, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllMyCoachTeam = () => {
    //Fixed
    return axios.get(API_URL + 'coach_team/mine', { headers: authHeader() }).then((response) => {
        response.data.forEach((team) => {
            if (team.team_image) team.team_image = process.env.REACT_APP_S3_URI + team.team_image;
            if (team.team_sponsor_logo) team.team_sponsor_logo = process.env.REACT_APP_S3_URI + team.team_sponsor_logo;
        });
        return response.data;
    });
};
const getMyCoachTeamList = () => {
    return axios.get(API_URL + 'coach_team/teams', { headers: authHeader() }).then((response) => {
        console.log('getMyCoachTeamList', response.data);
        return response.data;
    });
};
const getMyCoachPlayerList = () => {
    return axios.get(API_URL + 'coach_team/coach_players', { headers: authHeader() }).then((response) => {
        console.log('getMyCoachPlayerList', response.data);
        return response.data;
    });
};
const getAllMyCoachPlayer = () => {
    return axios.get(API_URL + 'coach_team/players', { headers: authHeader() }).then((response) => {
        console.log('getAllMyCoachPlayer', response.data);
        return response.data;
    });
};
const getCoachPlayerGames = (playerId) => {
    return axios.get(API_URL + `coach_team/player_games/${playerId}`, { headers: authHeader(), data: { id: playerId } }).then((response) => {
        console.log('getCoachPlayerGames', response.data);
        return response.data;
    });
};

const getCoachTeamPlayers = (teamId, seasonId, leagueId) => {
    //Fixed
    return axios.get(API_URL + `coach_team/team_players/${teamId}/${seasonId}/${leagueId}`, { headers: authHeader(), data: { teamId, seasonId, leagueId } }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        console.log('getCoachTeamPlayers', response.data);
        return response.data;
    });
};

const getGameCoachTeamPlayers = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `coach_team/game_team_players/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getGameOpponentPlayers = (teamId, gameIds) => {
    //Fixed
    return axios.get(API_URL + `coach_team/game_opponent_players/${teamId}/${gameIds}`, { headers: authHeader(), data: { teamId, gameIds } }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const addCoachTeam = (req) => {
    return axios.post(API_URL + 'coach_team', req, { headers: authHeader() }).then((response) => {
        console.log('addCoachTeam', response.data);
        return response.data;
    });
};
const getAllPlayerTagsByCoachPlayer = (req) => {
    return axios.post(API_URL + 'coach_team/get_tags_by_player', req, { headers: authHeader() }).then((response) => {
        console.log('getAllPlayerTagsByCoachPlayer', response.data);
        return response.data;
    });
};
const updateCoachTeam = (req) => {
    return axios.put(API_URL + `coach_team/${req.id}`, req, { headers: authHeader() }).then((response) => {
        console.log('updateCoachTeam', response.data);
        return response.data;
    });
};
const updateUserEdit = (req) => {
    //Fixed
    return axios.put(API_URL + `user_edits/${req.id}`, req, { headers: authHeader() }).then((response) => {
        console.log('updateUserEdit', response.data);
        return response.data;
    });
};
const addNewEditClips = (req) => {
    return axios.put(API_URL + `user_edit_clip/add/${req.id}`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const moveFolderNewPosition = (req) => {
    return axios.put(API_URL + `user_edit_folders/move`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const moveEditClips = (clipIds, editId) => {
    return axios.put(API_URL + `user_edit_clip/move/${clipIds}/${editId}`, { clipIds, editId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const copyEditClips = (clipIds, editId) => {
    return axios.put(API_URL + `user_edit_clip/copy/${clipIds}/${editId}`, { clipIds, editId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const clearUserSession = (req) => {
    return axios.post(API_URL + 'user/clearSession', req, { headers: authHeader() }).then((response) => {
        console.log('clearUserSession', response.data);
        return response.data;
    });
};
const deleteUserEdit = (id) => {
    return axios.delete(API_URL + `user_edits/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deleteUserEdit', response.data);
        return response.data;
    });
};
const deleteUserFolder = (id) => {
    return axios.delete(API_URL + `user_edits_folders/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deleteUserFolder', response.data);
        return response.data;
    });
};
const deleteEditClip = (clipIds) => {
    //Fixed
    return axios.delete(API_URL + `user_edits/edit_clip/${clipIds}`, { headers: authHeader(), data: { clipIds } }).then((response) => {
        return response.data;
    });
};
const deleteCoachTeam = (id) => {
    return axios.delete(API_URL + `coach_team/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deleteCoachTeam', response.data);
        return response.data;
    });
};
const addGame = (req) => {
    req.image = req.image ? (req.image.includes(process.env.REACT_APP_S3_URI) ? req.image.replace(process.env.REACT_APP_S3_URI, '') : req.image) : '';
    return axios.post(API_URL + 'game', req, { headers: authHeader() }).then((response) => {
        console.log('addGame', response.data);
        return response.data;
    });
};

const updateGame = (req) => {
    //Fixed
    req.image = req.image ? (req.image.includes(process.env.REACT_APP_S3_URI) ? req.image.replace(process.env.REACT_APP_S3_URI, '') : req.image) : '';
    return axios.put(API_URL + `game/${req.id}`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const updateTeam = (req) => {
    //Fixed
    req.image = req.image ? (req.image.includes(process.env.REACT_APP_S3_URI) ? req.image.replace(process.env.REACT_APP_S3_URI, '') : req.image) : '';
    req.sponsor_logo = req.sponsor_logo ? (req.sponsor_logo.includes(process.env.REACT_APP_S3_URI) ? req.sponsor_logo.replace(process.env.REACT_APP_S3_URI, '') : req.sponsor_logo) : '';
    return axios.put(API_URL + `team/${req.id}`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const updateLeague = (req) => {
    req.image = req.image ? (req.image.includes(process.env.REACT_APP_S3_URI) ? req.image.replace(process.env.REACT_APP_S3_URI, '') : req.image) : '';
    return axios.put(API_URL + `league/${req.id}`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const updateEditClipsSort = (req) => {
    return axios.put(API_URL + `user_edit_clips_sort`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const updateEditClip = (req) => {
    return axios.put(API_URL + `user_edit_clip/${req.id}`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const updatePlayersToplayersMinutesTable = (req) => {
    return axios.post(API_URL + `player/players_to_players_minutes_table`, req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
}

const getAllSeasons = () => {
    return axios.get(API_URL + 'season', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllLeagues = () => {
    //Fixed
    return axios.get(API_URL + 'league', { headers: authHeader() }).then((response) => {
        response.data.forEach((league) => {
            if (league.image) league.image = process.env.REACT_APP_S3_URI + league.image;
        });
        return response.data;
    });
};
const getAllActions = () => {
    return axios.get(API_URL + 'action', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const getAllActionTypes = () => {
    return axios.get(API_URL + 'action_type', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const getAllActionResults = () => {
    return axios.get(API_URL + 'action_result', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const addLeague = (req) => {
    return axios.post(API_URL + 'league', req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const addPlayer = (req) => {
    const playerData = {
        f_name: req.player_first_name ? req.player_first_name : req.f_name ?? null,
        l_name: req.player_last_name ? req.player_last_name : req.l_name ?? null,
        date_of_birth: req.player_date_of_birth ? req.player_date_of_birth : req.date_of_birth ?? null,
        image: req.player_image ? req.player_image : req.image ?? '',
        weight: req.player_weight === '' || !req.player_weight ? null : req.player_weight,
        height: req.player_height === '' || !req.player_height ? null : req.player_height,
        strong_foot: req.player_strong_foot === '' || !req.player_strong_foot ? null : req.player_strong_foot,
        age_group_id: Number(req.player_age_group_id) === 0 ? null : req.player_age_group_id,
        jersey_number: req.player_jersey_number ? req.player_jersey_number : req.jersey_number ?? null,
        position: req.player_position_id ? (Number(req.player_position_id) === 0 ? null : req.player_position_id) : req.position && req.position.id ? req.position.id : null,

        second_position: Number(req.player_second_position_id) === 0 ? null : req.player_second_position_id,
        position_role_one: Number(req.player_position_role_one_id) ?? null,
        position_role_two: Number(req.player_position_role_two_id) ?? null,
        active: req?.player_active ?? true

    };

    return axios.post(API_URL + 'player', playerData, { headers: authHeader() }).then((response) => {
        console.log('addPlayer', response.data);
        return response.data;
    });
};

const deletePlayersInTeam = (id) => {
    return axios.delete(API_URL + `team_player/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deletePlayersInTeam', response.data);
        return response.data;
    });
};
const deleteTeamTag = (id) => {
    return axios.delete(API_URL + `team_tag/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deleteTeamTag', response.data);
        return response.data;
    });
};

const deleteGame = (id) => {
    return axios.delete(API_URL + `game/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deleteGame', response.data);
        return response.data;
    });
};

const deleteTeam = (id) => {
    return axios.delete(API_URL + `team/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        return response.data;
    });
};
const deleteLeague = (id) => {
    return axios.delete(API_URL + `league/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        return response.data;
    });
};

const deletePlayerTag = (id) => {
    return axios.delete(API_URL + `player_tag/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deletePlayerTag', response.data);
        return response.data;
    });
};
const deletePlayer = (id) => {
    return axios.delete(API_URL + `player/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('deletePlayer', response.data);
        return response.data;
    });
};
const deleteCorrection = (id) => {
    return axios.delete(API_URL + `player/correction/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        return response.data;
    });
};

const getGame = (id) => {
    return axios.get(API_URL + `game/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getGame', response.data);
        return response.data;
    });
};

const getAllGames = () => {
    //Fixed
    return axios.get(API_URL + 'game', { headers: authHeader() }).then((response) => {
        response.data.forEach((game) => {
            if (game.image || game.image !== '') game.image = process.env.REACT_APP_S3_URI + game.image;
        });
        console.log('getAllGames', response.data);
        return response.data;
    });
};
const getTeamInitialStanding = () => {
    //Fixed
    return axios.get(API_URL + 'game/standing', { headers: authHeader() }).then((response) => {
        response.data.forEach((formation) => {
            if (formation.image || formation.image !== '') formation.image = process.env.REACT_APP_S3_URI + formation.image;
        });
        return response.data;
    });
};

const getAllPlayers = () => {
    //Fixed
    return axios.get(API_URL + 'player', { headers: authHeader() }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getAllCoach = () => {
    return axios.get(API_URL + 'user/coach', { headers: authHeader() }).then((response) => {
        console.log('getAllCoach', response.data);
        return response.data;
    });
};

const getAllUsers = () => {
    return axios.get(API_URL + 'user/all', { headers: authHeader() }).then((response) => {
        response.data.forEach((user) => {
            if (user.user_image) user.user_image = process.env.REACT_APP_S3_URI + user.user_image;
        });
        console.log('getAllUsers', response.data);
        return response.data;
    });
};

const getAllUserSubscriptions = () => {
    return axios.get(API_URL + 'user/all_subscription', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const deleteUser = (userId) => {
    return axios.delete(API_URL + `user/delete/${userId}`, { headers: authHeader(), data: { userId } }).then((response) => {
        console.log('deleteUser', response.data);
        return response.data;
    });
};

const updateUser = (req) => {
    return axios.post(API_URL + 'user/update', req, { headers: authHeader() }).then((response) => {
        console.log('updateUser', response.data);
        return response.data;
    });
};

const addNewUser = (req) => {
    return axios.post(API_URL + 'user/add', req, { headers: authHeader() }).then((response) => {
        console.log('addNewUser', response.data);
        return response.data;
    });
};

const getAllRepresentatives = () => {
    return axios.get(API_URL + 'user/representative', { headers: authHeader() }).then((response) => {
        console.log('getAllRepresentatives', response.data);
        return response.data;
    });
};

const addRepresentative = (userId) => {
    return axios.put(API_URL + `user/representative/add/${userId}`, { userId }, { headers: authHeader() }).then((response) => {
        console.log('addRepresentative', response.data);
        return response.data;
    });
};

const deleteRepresentative = (roleId, userId) => {
    return axios.delete(API_URL + `user/representative/delete/${roleId}/${userId}`, { headers: authHeader(), data: { roleId, userId } }).then((response) => {
        console.log('deleteRepresentative', response.data);
        return response.data;
    });
};

const addAcademy = (name, country) => {
    return axios.put(API_URL + `user/academy/add/${name}/${country}`, { name, country }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const deleteAcademy = (id) => {
    return axios.delete(API_URL + `user/academy/delete/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        return response.data;
    });
};

const editAcademy = (id, name, country) => {
    return axios.put(API_URL + `user/academy/update/${id}/${name}/${country}`, { id, name, country }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const addAcademyToRepresentative = (userId, academyId) => {
    return axios.put(API_URL + `user/representative_academy/add/${userId}/${academyId}`, { userId, academyId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAcademiesForRepresentative = (userId) => {
    return axios.get(API_URL + `user/representative_academy/get/${userId}`, { headers: authHeader(), data: { userId } }).then((response) => {
        return response.data;
    });
};

const deleteAcademyFromRepresentative = (userId, academyId) => {
    return axios.delete(API_URL + `user/representative_academy/delete/${userId}/${academyId}`, { headers: authHeader(), data: { userId, academyId } }).then((response) => {
        return response.data;
    });
};

const addHideGame = (academyId, gameId, teamId) => {
    return axios.put(API_URL + `game/hide_game/add/${academyId}/${gameId}/${teamId}`, { academyId, gameId, teamId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getHideGame = (academyId) => {
    return axios.get(API_URL + `game/hide_game/get/${academyId}`, { headers: authHeader(), data: { academyId } }).then((response) => {
        return response.data;
    });
};

const getTeamsFromHide = () => {
    return axios.get(API_URL + `game/hide_game/team`, { headers: authHeader() }).then((response) => {
        console.log('getTeamsFromHide', response.data);
        return response.data;
    });
};

const getHidGamesByTeam = (teamId) => {
    return axios.get(API_URL + `game/hide_game/game/${teamId}`, { headers: authHeader(), data: { teamId } }).then((response) => {
        console.log('getHidGamesByTeam', response.data);
        return response.data;
    });
};

const deleteHideGame = (academyId, gameId, teamId) => {
    return axios.delete(API_URL + `game/hide_game/delete/${academyId}/${gameId}/${teamId}`, { headers: authHeader(), data: { academyId, gameId, teamId } }).then((response) => {
        return response.data;
    });
};

const deleteTeamFromHideGame = (teamId) => {
    return axios.delete(API_URL + `game/hide_game/delete/team/${teamId}`, { headers: authHeader(), data: { teamId } }).then((response) => {
        return response.data;
    });
};

const deleteGameFromHideGame = (gameId) => {
    return axios.delete(API_URL + `game/hide_game/delete/game/${gameId}`, { headers: authHeader(), data: { gameId } }).then((response) => {
        return response.data;
    });
};

const addTeamToAcademy = (userId, academyId, seasonId, teamId) => {
    return axios.put(API_URL + `user/academy_team/add/${userId}/${academyId}/${seasonId}/${teamId}`, { userId, academyId, seasonId, teamId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getTeamsByAcademy = (userId, academyId, seasonId) => {
    return axios.get(API_URL + `user/academy_team/get/${userId}/${academyId}/${seasonId}`, { headers: authHeader(), data: { userId, academyId, seasonId } }).then((response) => {
        console.log('getTeamsByAcademy', response.data);
        return response.data;
    });
};

const deleteTeamsFromAcademy = (userId, academyId, seasonId, teamId) => {
    return axios
        .delete(API_URL + `user/academy_team/delete/${userId}/${academyId}/${seasonId}/${teamId}`, { headers: authHeader(), data: { userId, academyId, seasonId, teamId } })
        .then((response) => {
            return response.data;
        });
};

const addAcademyCoach = (userId, academyId) => {
    return axios.put(API_URL + `user/academy_coach/add/${userId}/${academyId}`, { userId, academyId }, { headers: authHeader() }).then((response) => {
        console.log('addAcademyCoach', response.data);
        return response.data;
    });
};

const updateSubscription = (subId, scriptId, start, end) => {
    return axios.put(API_URL + `user/subscription/update/${subId}/${scriptId}/${start}/${end}`, { subId, scriptId, start, end }, { headers: authHeader() }).then((response) => {
        console.log('updateSubscription', response.data);
        return response.data;
    });
};

const addUserSubscription = (userId, subId, start, end) => {
    return axios.put(API_URL + `user/subscription/add/${userId}/${subId}/${start}/${end}`, { userId, subId, start, end }, { headers: authHeader() }).then((response) => {
        console.log('addUserSubscription', response.data);
        return response.data;
    });
};

const getAllSubscriptions = () => {
    return axios.get(API_URL + `user/subscription/all`, { headers: authHeader() }).then((response) => {
        console.log('getAllSubscriptions', response.data);
        return response.data;
    });
};

const getAllCoachesByTeam = (seasonId, leagueId, teamId) => {
    return axios.get(API_URL + `user/coach/${seasonId}/${leagueId}/${teamId}`, { headers: authHeader(), data: { seasonId, leagueId, teamId } }).then((response) => {
        console.log('getAllCoachesByTeam', response.data);
        return response.data;
    });
};

const getAcademyCoach = (userId) => {
    return axios.get(API_URL + `user/academy_coach/get/${userId}`, { headers: authHeader(), data: { userId } }).then((response) => {
        console.log('getAcademyCoach', response.data);
        return response.data;
    });
};

const getAllAcademyCoaches = () => {
    return axios.get(API_URL + `user/academy_coach/all`, { headers: authHeader() }).then((response) => {
        console.log('getAllAcademyCoaches', response.data);
        return response.data;
    });
};

const deleteAcademyCoach = (userId, academyId) => {
    return axios.delete(API_URL + `user/academy_coach/delete/${userId}/${academyId}`, { headers: authHeader(), data: { userId, academyId } }).then((response) => {
        console.log('deleteAcademyCoach', response.data);
        return response.data;
    });
};

const getAllAcademies = (userId) => {
    return axios.get(API_URL + `user/academy/get/all`, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const sendEmailToUser = (req) => {
    return axios.put(API_URL + `game/send_email/${req.id}`, req, { headers: authHeader(), data: { id: req.id } }).then((response) => {
        return response.data;
    });
};

const getAllPositions = () => {
    return axios.get(API_URL + 'player/position', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllRoles = () => {
    return axios.get(API_URL + 'player/role', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllAgeGroups = () => {
    return axios.get(API_URL + 'player/age_group', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getPlayersByTeam = (teamId, seasonId) => {
    return axios.get(API_URL + `player/team/${teamId}/${seasonId}`, { headers: authHeader(), data: { teamId, seasonId } }).then((response) => {
        return response.data;
    });
};

const getPlayersMinutes = (gameId, teamId) => {
    return axios.get(API_URL + `player/minutes/${gameId}/${teamId}`, { headers: authHeader(), data: { gameId, teamId } }).then((response) => {
        return response.data;
    });
};

const editPlayersMinutes = (minId, req) => {
    return axios.put(API_URL + `player/minutes/${minId}`, req, { headers: authHeader(), data: { id: minId } }).then((response) => {
        console.log('editPlayersMinutes', response.data);
        return response.data;
    });
};

const updatePlayerTag = (req) => {
    return axios.put(API_URL + `player_tag/${req.id}`, req, { headers: authHeader(), data: { id: req.id } }).then((response) => {
        return response.data;
    });
};

const updatePlayerTagByManual = (req) => {
    return axios.put(API_URL + `player_tag/update/${req.id}`, req, { headers: authHeader(), data: { id: req.id } }).then((response) => {
        return response.data;
    });
};

//
const updatePlayer = (req) => {

    const update_data = {
        id: req.player_id ?? req.id,
        f_name: req.player_first_name ? req.player_first_name : req.f_name ?? null,
        l_name: req.player_last_name ? req.player_last_name : req.l_name ?? null,
        date_of_birth: req.player_date_of_birth ? req.player_date_of_birth : req.date_of_birth ?? null,
        image: req.player_image
            ? req.player_image.includes(process.env.REACT_APP_S3_URI)
                ? req.player_image.replace(process.env.REACT_APP_S3_URI, '')
                : req.player_image
            : req.image
                ? req.image.includes(process.env.REACT_APP_S3_URI)
                    ? req.image.replace(process.env.REACT_APP_S3_URI, '')
                    : req.image
                : '',
        weight: req.player_weight ? req.player_weight : req.weight ?? null,
        height: req.player_height ? req.player_height : req.height ?? null,
        strong_foot: req.player_strong_foot ? req.player_strong_foot : req.strong_foot ?? null,
        age_group_id: req.player_age_group_id ? req.player_age_group_id : req.age_group_id ?? null,
        jersey_number: req.player_jersey_number ? req.player_jersey_number : req.jersey_number ?? null,
        position: req.player_position_id ? req.player_position_id : req.position ?? null,
        second_position: req.player_second_position_id ? req.player_second_position_id : req.second_position ?? null,
        position_role_one: Number(req.player_position_role_one_id) ? Number(req.player_position_role_one_id) : req.position_role_one ?? null,
        position_role_two: Number(req.player_position_role_two_id) ? Number(req.player_position_role_two_id) : req.position_role_two ?? null,
        active: req?.player_active ?? true

    };
    return axios.put(API_URL + `player/${req.player_id ?? req.id}`, update_data, { headers: authHeader(), data: { id: req.player_id ?? req.id } }).then((response) => {
        return response.data;
    });
};

const updateTeamTag = (req) => {
    return axios.put(API_URL + `team_tag/${req.id}`, req, { headers: authHeader(), data: { id: req.id } }).then((response) => {
        return response.data;
    });
};

const updateJersey = (req) => {
    return axios.post(API_URL + 'team_player/updatejersey', req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const updateTaggerConfig = (req) => {
    return axios.post(API_URL + 'user/updateConfig', req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const addTeamTag = (req) => {
    return axios.post(API_URL + 'team_tag', req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const addPlayerTag = (req) => {
    return axios.post(API_URL + 'player_tag', req, { headers: authHeader() }).then((response) => {
        console.log('addPlayerTag', response.data);
        return response.data;
    });
};

const addHighlight = (req) => {
    return axios.post(API_URL + 'player/highlight', req, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const addUserEdits = (req) => {
    return axios.post(API_URL + 'user_edits', req, { headers: authHeader() }).then((response) => {
        console.log('addUserEdits', response.data);
        return response.data;
    });
};

const getAllUserEdits = () => {
    return axios.get(API_URL + 'user_edits', { headers: authHeader() }).then((response) => {
        console.log('getAllUserEdits', response.data);
        return response.data;
    });
};

const getEditClipsByUserEditId = (id) => {
    //Fixed
    return axios.get(API_URL + `user_edits/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
            if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
        });
        return response.data;
    });
};

const getAllTeamTagsByGame = (id) => {
    return axios.get(API_URL + `team_tag/getbygame/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        return response.data;
    });
};

const getAllPlayerTagsByTeamTag = (id) => {
    return axios.get(API_URL + `player_tag/getbyteamtag/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getAllPlayerTagsByTeamTag', response.data);
        return response.data;
    });
};

const getTeamById = (id) => {
    return axios.get(API_URL + `team/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        if (response.data.sponsor_logo) response.data.sponsor_logo = process.env.REACT_APP_S3_URI + response.data.sponsor_logo;
        if (response.data.image) response.data.image = process.env.REACT_APP_S3_URI + response.data.image;
        return response.data;
    });
};

const getTeamsStatsAdvanced = (req) => {
    return axios.post(API_URL + `team/getteamsstats/advance`, req, { headers: authHeader() }).then((response) => {
        console.log('getTeamsStatsAdvanced', response.data);
        return response.data;
    });
};

const getTeamsStatsGamebyGame = (req) => {
    return axios.post(API_URL + `team/getteamsstats/game`, req, { headers: authHeader() }).then((response) => {
        console.log('getTeamsStatsGamebyGame', response.data);
        return response.data;
    });
};

const getTeamsStatsGamebyGameSummary = (req) => {
    return axios.post(API_URL + `team/getteamsstats/gameSummary`, req, { headers: authHeader() }).then((response) => {
        console.log('getTeamsStatsGamebyGameSummary', response.data);
        return response.data;
    });
};

const getSeasonById = (id) => {
    return axios.get(API_URL + `season/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getSeasonById', response.data);
        return response.data;
    });
};

const getLeagueById = (id) => {
    return axios.get(API_URL + `league/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getLeagueById', response.data);
        return response.data;
    });
};

const getPlayerById = (id) => {
    //Fixed
    return axios.get(API_URL + `player/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        if (response.data.player_image) response.data.player_image = process.env.REACT_APP_S3_URI + response.data.player_image;
        if (response.data.team_image) response.data.team_image = process.env.REACT_APP_S3_URI + response.data.team_image;
        if (response.data.sponsor_logo) response.data.sponsor_logo = process.env.REACT_APP_S3_URI + response.data.sponsor_logo;
        return response.data;
    });
};

const getAllHighlightByPlayerId = (id) => {
    return axios.get(API_URL + `player/highlight/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getAllHighlightByPlayerId', response.data);
        return response.data;
    });
};
const getAllGamesByPlayer = (id) => {
    return axios.get(API_URL + `player/gameByPlayerId/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getAllGamesByPlayer', response.data);
        return response.data;
    });
};

const getGameDetailssByPlayer = (id) => {
    //Fixed
    return axios.get(API_URL + `player/gameDetailsByPlayerId/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        response.data.forEach((game) => {
            if (game.away_team_image) game.away_team_image = process.env.REACT_APP_S3_URI + game.away_team_image;
            if (game.home_team_image) game.home_team_image = process.env.REACT_APP_S3_URI + game.home_team_image;
            if (game.image) game.image = process.env.REACT_APP_S3_URI + game.image;
        });
        return response.data;
    });
};

const getPlayersStats = (seasonId, leagueId, gameId, teamId, playerId) => {
    return axios
        .get(API_URL + `player/getplayersstats/${seasonId}/${leagueId}/${gameId}/${teamId}/${playerId}`, { headers: authHeader(), data: { seasonId, leagueId, gameId, teamId, playerId } })
        .then((response) => {
            console.log('getPlayersStats', response.data);
            return response.data;
        });
};

const getPlayersDetection = (gameId, videoTime, minBefore, minAfter) => {
    return axios
        .get(API_URL + `player/player_detection/${gameId}/${videoTime}/${minBefore}/${minAfter}`, { headers: authHeader(), data: { gameId, videoTime, minBefore, minAfter } })
        .then((response) => {
            return response.data;
        });
};

const getPlayersGames = (season, teams, players) => {
    //Fixed
    return axios.get(API_URL + `player/games/${season}/${teams}/${players}`, { headers: authHeader(), data: { season, teams, players } }).then((response) => {
        return response.data;
    });
};

const getPlayersTeams = (season, players) => {
    return axios.get(API_URL + `player/teams/${season}/${players}`, { headers: authHeader(), data: { season, players } }).then((response) => {
        console.log('getPlayersTeams', response.data);
        return response.data;
    });
};

const getTeamsGames = (season, teams) => {
    return axios.get(API_URL + `team/games/${season}/${teams}`, { headers: authHeader(), data: { season, teams } }).then((response) => {
        console.log('getTeamsGames', response.data);
        return response.data;
    });
};

const getPlayersStatsAdvanced = (req) => {
    return axios.post(API_URL + `player/getplayersstats/advance`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        console.log('getPlayersStatsAdvanced', response.data);
        return response.data;
    });
};

const getPlayersStatsAdvanceSummary = (req) => {
    //Fixed
    return axios.post(API_URL + `player/getplayersstats/summary`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getPlayersStatsGamebyGame = (req) => {
    return axios.post(API_URL + `player/getplayersstats/game`, req, { headers: authHeader() }).then((response) => {
        console.log('getPlayersStatsGamebyGame', response.data);
        return response.data;
    });
};

const getPlayersStatsGamebyGameStandAlone = (req) => {
    return axios.post(API_URL + `player/getplayersstats/game/standalone`, req).then((response) => {
        return response.data;
    });
};


const getGoalkeepersStatsAdvanced = (req) => {
    return axios.post(API_URL + `player/getgoalkeepersstats/advance`, req, { headers: authHeader() }).then((response) => {
        console.log('getGoalkeepersStatsAdvanced', response.data);
        return response.data;
    });
};

const getGoalkeepersStatsAdvanceSummary = (req) => {
    //Fixed
    return axios.post(API_URL + `player/getgoalkeepersstats/summary`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((player) => {
            if (player.player_image) player.player_image = process.env.REACT_APP_S3_URI + player.player_image;
        });
        return response.data;
    });
};

const getGoalkeepersStatsGamebyGame = (req) => {
    return axios.post(API_URL + `player/getgoalkeepersstats/game`, req, { headers: authHeader() }).then((response) => {
        console.log('getGoalkeepersStatsGamebyGame', response.data);
        return response.data;
    });
};

const getGoalkeepersStatsGamebyGameStandAlone = (req) => {
    return axios.post(API_URL + `player/getgoalkeepersstats/game/standalone`, req).then((response) => {
        return response.data;
    });
};

const addCorrectionRequest = (curPlayerId, newPlayerId, playerTagId) => {
    return axios.put(API_URL + `player/addcorrection/${curPlayerId}/${newPlayerId}/${playerTagId}`, { curPlayerId, newPlayerId, playerTagId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const doCorrection = (cId) => {
    return axios.put(API_URL + `player/docorrection/${cId}`, { cId }, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};
const getCorrectionRequest = () => {
    return axios.get(API_URL + `player/request/getcorrection`, { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const getAllPlayerTagsByPlayer = (playerId, gameId) => {
    return axios.get(API_URL + `player_tag/getbyplayer/${playerId}/${gameId}`, { headers: authHeader(), data: { playerId, gameId } }).then((response) => {
        console.log('getAllPlayerTagsByPlayer', response.data);
        return response.data;
    });
};

const getPlayerTagsByActionName = (playerId, gameId, actionName) => {
    return axios.get(API_URL + `player_tag/getbyaction/${playerId}/${gameId}/${actionName}`, { headers: authHeader(), data: { playerId, gameId, actionName } }).then((response) => {
        console.log('getPlayerTagsByActionName', response.data);
        return response.data;
    });
};

const getAllPlayerTagsByTeam = (teamId, gameId) => {
    return axios.post(API_URL + `player_tag/getbyteam`, { teamId, gameId }, { headers: authHeader() }).then((response) => {
        console.log('getAllPlayerTagsByTeam', response.data);
        return response.data;
    });
};

const getGamePlayerTags = (userId, teamId, playerId, gameId, actionId, actionTypeId, actionResultId, gameTime, courtArea, inside, gameResult, homeAway) => {
    return axios
        .get(API_URL + `player/game_player_tags/${userId}/${teamId}/${playerId}/${gameId}/${actionId}/${actionTypeId}/${actionResultId}/${gameTime}/${courtArea}/${inside}/${gameResult}/${homeAway}`, {
            headers: authHeader(),
            data: { userId, teamId, playerId, gameId, actionId, actionTypeId, actionResultId, gameTime, courtArea, inside, gameResult, homeAway }
        })
        .then((response) => {
            response.data.forEach((game) => {
                if (game.away_team_logo) game.away_team_logo = process.env.REACT_APP_S3_URI + game.away_team_logo;
                if (game.home_team_logo) game.home_team_logo = process.env.REACT_APP_S3_URI + game.home_team_logo;
            });
            return response.data;
        });
};

const getOpponentTags = (userId, teamId, playerId, gameId, actionId, actionTypeId, actionResultId, gameTime, courtArea, inside, gameResult, homeAway) => {
    return axios
        .get(API_URL + `player/opponent_tags/${userId}/${teamId}/${playerId}/${gameId}/${actionId}/${actionTypeId}/${actionResultId}/${gameTime}/${courtArea}/${inside}/${gameResult}/${homeAway}`, {
            headers: authHeader(),
            data: { userId, teamId, playerId, gameId, actionId, actionTypeId, actionResultId, gameTime, courtArea, inside, gameResult, homeAway }
        })
        .then((response) => {
            return response.data;
        });
};

const getGameScore = (gameId) => {
    return axios.get(API_URL + `player_tag/getgamescore/${gameId}`, { headers: authHeader(), data: { gameId } }).then((response) => {
        console.log('getGameScore', response.data);
        return response.data;
    });
};

const getScoreInGames = (gameIds, teamId) => {
    return axios.post(API_URL + `game/getscoreingames`, { gameIds, teamId }, { headers: authHeader() }).then((response) => {
        console.log('getScoreInGames', response.data);
        return response.data;
    });
};

// NEW STREAM URL
const getAsyncNewStreamURL = async (url) => {
    return (await axios.post(API_URL + 'game/getnewstream', { video_url: url }, { headers: authHeader() })).data;
};
const getNewStreamURL = (url) => {
    return axios.post(API_URL + 'game/getnewstream', { video_url: url }, { headers: authHeader() }).then((response) => {
        console.log('getNewStreamURL', response.data);
        return response.data;
    });
};
const getPlayerActions = (gameIds, teamId) => {
    return axios.post(API_URL + 'game/getplayeractions', { gameIds, teamId }, { headers: authHeader() }).then((response) => {
        console.log('getPlayerActions', response.data);
        return response.data;
    });
};

const getAllGamesByTeam = (season, league, team) => {
    return axios.get(API_URL + `game/getbyteam/${season}/${league}/${team}`, { headers: authHeader(), data: { season, league, team } }).then((response) => {
        response.data.forEach((game) => {
            if (game.image || game.image !== '') game.image = process.env.REACT_APP_S3_URI + game.image;
        });
        console.log('getAllGamesByTeam', response.data);
        return response.data;
    });
};

const getTeamByPlayerGame = (playerId, gameId) => {
    //Fixed
    return axios.get(API_URL + `team_player/teambyplayergame/${playerId}/${gameId}`, { headers: authHeader(), data: { playerId, gameId } }).then((response) => {
        if (response.data.image) response.data.image = process.env.REACT_APP_S3_URI + response.data.image;
        if (response.data.sponsor_logo) response.data.sponsor_logo = process.env.REACT_APP_S3_URI + response.data.sponsor_logo;
        return response.data;
    });
};

const getBiggestSortNumber = (type, parentId) => {
    return axios.get(API_URL + `user_edits/big_sort/${type}/${parentId}`, { headers: authHeader(), data: { type, parentId } }).then((response) => {
        console.log('getBiggestSortNumber', response.data);
        return response.data;
    });
};

const getVideoSourceFromEdit = (type, parentId) => {
    return axios.get(API_URL + `user_edits/video_source/${parentId}`, { headers: authHeader(), data: { parentId } }).then((response) => {
        console.log('getVideoSourceFromEdit', response.data);
        return response.data;
    });
};

const getAllFolders = () => {
    //Fixed
    return axios.get(API_URL + 'user_edits_folders_all', { headers: authHeader() }).then((response) => {
        return response.data;
    });
};

const sendShareEmail = (req) => {
    return axios.post(API_URL + `user_edits/share`, req, { headers: authHeader() }).then((response) => {
        console.log('sendShareEmail', response.data);
        return response.data;
    });
};

const getEditbyId = (id) => {
    return axios.get(API_URL + `user_edits/get/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getEditbyId', response.data);
        return response.data;
    });
};

const getShareURL = (id) => {
    return axios.get(API_URL + `user_edits/get_share/${id}`, { headers: authHeader(), data: { id } }).then((response) => {
        console.log('getShareURL', response.data);
        return response.data;
    });
};

const verifyShareId = (req) => {
    return axios.post(API_URL + 'user_edits_verify', req, { headers: { 'x-access-token': '' } }).then((response) => {
        console.log('verifyShareId', response.data);
        return response.data;
    });
};

const createUserFolder = (req) => {
    return axios.post(API_URL + 'user_edits_folders', req, { headers: authHeader() }).then((response) => {
        console.log('createUserFolder', response.data);
        return response.data;
    });
};

const createUserEdit = (req) => {
    return axios.post(API_URL + 'user_edits_create', req, { headers: authHeader() }).then((response) => {
        console.log('createUserEdit', response.data);
        return response.data;
    });
};

const createUserXml = (req) => {
    return axios.post(API_URL + 'user_xml_create', req, { headers: authHeader() }).then((response) => {
        console.log('createUserXml', response.data);
        return response.data;
    });
};

const updateUserXml = (req) => {
    return axios.put(API_URL + `user_xml/${req.id}`, req, { headers: authHeader() }).then((response) => {
        console.log('updateUserXml', response.data);
        return response.data;
    });
};

const getUserXmlAll = () => {
    return axios.get(API_URL + 'user_xml_all', { headers: authHeader() }).then((response) => {
        console.log('getUserXmlAll', response.data);
        return response.data;
    });
};

const getUserXml = (id) => {
    return axios.get(API_URL + `user_xml/${id}`, { headers: authHeader() }).then((response) => {
        console.log('getUserXml', response.data);
        return response.data;
    });
};

const deleteXml = (id) => {
    return axios.delete(API_URL + `user_xml/${id}`, { headers: authHeader() }).then((response) => {
        console.log('deleteXml', response.data);
        return response.data;
    });
};

const deleteVideo = (id) => {
    return axios.delete(API_URL + `delete_video/${id}`, { headers: authHeader() }).then((response) => {
        console.log('deleteVideo', response.data);
        return response.data;
    });
};

const getxmlStatus = (id) => {
    return axios.get(API_URL + `user_xml_status/${id}`, { headers: authHeader() }).then((response) => {
        console.log('getxmlStatus', response.data);
        return response.data;
    });
};

const getGamePosessions = (id) => {
    return axios.get(API_URL + `get_game_posessions/${id}`, { headers: authHeader() }).then((response) => {
        console.log('getGamePosessions', response.data);
        return response.data;
    });
};

// const updatePlayerDetection = (gameId, playerUniqueId, playerId, playerJerseyNumber) => {
//     return axios.put(API_URL + `player/updateplayersdetections/${gameId}/${playerUniqueId}/${playerId}/${playerJerseyNumber}`, { gameId, playerUniqueId, playerId, playerJerseyNumber }, { headers: authHeader() }).then((response) => {
//         return response.data;
//     });
// }

const updatePlayerDetection = (gameId, playerUniqueId, playerId, playerJerseyNumber, fromTime, toTime) => {
    return axios
        .put(
            API_URL + `player/updateplayersdetections/${gameId}/${playerUniqueId}/${playerId}/${playerJerseyNumber}`,
            { gameId, playerUniqueId, playerId, playerJerseyNumber, fromTime, toTime },
            { headers: authHeader() }
        )
        .then((response) => {
            console.log('updatePlayerDetection', response.data);
            return response.data;
        });
};

const getFormationAnalysisSummary = (req) => {
    //Fixed
    return axios.post(API_URL + `team/formation_analysis_summary`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((game) => {
            if (game.team_formation_image) game.team_formation_image = process.env.REACT_APP_S3_URI + game.team_formation_image;
        });
        return response.data;
    });
};

const getFormationAnalysis = (req) => {
    //Fixed
    return axios.post(API_URL + `team/formation_analysis`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((game) => {
            if (game.team_formation_image) game.team_formation_image = process.env.REACT_APP_S3_URI + game.team_formation_image;
            if (game.opponent_formation_image) game.opponent_formation_image = process.env.REACT_APP_S3_URI + game.opponent_formation_image;
        });
        return response.data;
    });
};

const getFormationAnalysisByGame = (req) => {
    //Fixed
    return axios.post(API_URL + `team/formation_analysis_bygame`, req, { headers: authHeader() }).then((response) => {
        response.data.forEach((game) => {
            if (game.team_formation_image) game.team_formation_image = process.env.REACT_APP_S3_URI + game.team_formation_image;
        });
        return response.data;
    });
};

const gameService = {
    addTeamPlayer,
    addTeam,
    addLeague,
    addGame,
    addPlayer,
    addTeamTag,
    addPlayerTag,
    addCoachTeam,
    addHighlight,
    addRepresentative,
    addAcademy,
    addAcademyToRepresentative,
    addTeamToAcademy,
    addHideGame,
    addAcademyCoach,
    addNewUser,
    addMultiple,
    addUserSubscription,

    addUserEdits,
    createUserFolder,
    createUserEdit,
    createUserXml,

    getGame,
    getAllUserEdits,
    getEditClipsByUserEditId,
    getTeamById,
    getTeamsStatsAdvanced,
    getTeamsStatsGamebyGame,
    getTeamsStatsGamebyGameSummary,
    getSeasonById,
    getLeagueById,
    getPlayerById,
    getHideGame,
    getTeamsFromHide,
    getHidGamesByTeam,

    getGameTeamPlayers,
    getAllTeamPlayers,
    getAllTeams,
    getAllLeagues,
    getAllGames,
    getAllSeasons,
    getAllPlayers,
    getAllTeamTagsByGame,
    getAllPlayerTagsByTeamTag,
    getAllActions,
    getAllActionTypes,
    getAllActionResults,
    getAllPositions,
    getAllRoles,
    getAllAgeGroups,
    getPlayersByTeam,
    getAllGamesByPlayer,
    getAllPlayerTagsByPlayer,
    getAllPlayerTagsByTeam,
    getGamePlayerTags,
    getOpponentTags,
    getAllCoach,
    getAllUsers,
    getAllUserSubscriptions,
    getAllRepresentatives,
    getAcademiesForRepresentative,
    getTeamsByAcademy,
    getAcademyCoach,
    getAllAcademyCoaches,
    getAllAcademies,
    getAllCoachTeam,
    getAllGamesByCoach,
    getAdditionalGames,
    getGameById,
    getCleanGame,
    getTeamGoals,
    getOpponentGoals,
    getTeamGoalOpportunity,
    getOpponentGoalOpportunity,
    getTeamOffensivePossession,
    getOpponentOffensivePossession,
    getTeamDefensivePossession,
    getOpponentDefensivePossession,
    getTeamBuildupGoalkeeper,
    getOpponentBuildupGoalkeeper,
    getTeamBuildupGoalkeeperKick,
    getOpponentBuildupGoalkeeperKick,
    getTeamBuildonDefensiveHalf,
    getOpponentBuildonDefensiveHalf,
    getTeamInterception,
    getOpponentInterception,
    getTeamTackle,
    getOpponentTackle,
    getTeamThrowIn,
    getOpponentThrowIn,
    getTeamFreekick,
    getOpponentFreekick,
    getTeamCorner,
    getOpponentCorner,
    getTeamCross,
    getOpponentCross,
    getTeamPenalty,
    getOpponentPenalty,
    getTeamDrawfoul,
    getOpponentDrawfoul,
    getTeamOffside,
    getOpponentOffside,
    getTeamShots,
    getOpponentShots,
    getGameHighlight,
    getTeamBuildOnOffensiveHalf,
    getOpponentBuildOnOffensiveHalf,
    getTeamCounterAttack,
    getOpponentCounterAttack,
    getTeamTurnover,
    getOpponentTurnover,
    getTeamSaved,
    getOpponentSaved,
    getTeamClearance,
    getOpponentClearance,
    getTeamBlocked,
    getOpponentBlocked,
    gameExportSportcode,
    gameExportSportcodeShort,
    getAllMyCoachTeam,
    getMyCoachTeamList,
    getMyCoachPlayerList,
    getAllMyCoachPlayer,
    getAllHighlightByPlayerId,
    getAllGamesByTeam,
    getGameTeamPlayersByTeam,
    getTeamByPlayerGame,
    getGameDetailssByPlayer,
    getPlayersStats,
    getPlayersStatsAdvanced,
    getPlayersStatsAdvanceSummary,
    getPlayersStatsGamebyGame,
    getPlayersMinutes,
    editPlayersMinutes,
    getGoalkeepersStatsAdvanced,
    getGoalkeepersStatsAdvanceSummary,
    getGoalkeepersStatsGamebyGame,
    getPlayersDetection,
    getPlayersGames,
    getPlayersTeams,
    getTeamsGames,
    addCorrectionRequest,
    getCorrectionRequest,
    doCorrection,
    getPlayerTagsByActionName,
    getGameScore,
    getScoreInGames,
    getAsyncNewStreamURL,
    getNewStreamURL,
    getPlayerActions,
    getAllPlayersByCoach,
    getAllLeaguesByCoach,
    getAllTeamsByCoach,
    getAllTeamsByLeagueSeason,
    getAllLeaguesOfAdditionalGamesByCoach,
    getAllTeamsOfAdditionalGamesByCoach,
    getNumberOfGamesOrdered,
    getCoachPlayerGames,
    getCoachTeamPlayers,
    getGameCoachTeamPlayers,
    getGameOpponentPlayers,
    getAllPlayerTagsByCoachPlayer,
    getAllGameTeamPlayers,
    getAllFolders,
    getBiggestSortNumber,
    getVideoSourceFromEdit,
    getTeamInitialStanding,
    getEditbyId,
    getShareURL,
    getAllSubscriptions,
    getAllCoachesByTeam,
    getAllMultipleData,
    getAllMultipleDataByIds,
    getGamesByIds,
    getUserXmlAll,
    getUserXml,
    getxmlStatus,
    getGamePosessions,
    getFormationAnalysisSummary,
    getFormationAnalysis,
    getFormationAnalysisByGame,

    updatePlayersToplayersMinutesTable,

    updateJersey,
    updateGame,
    updateTeam,
    updateLeague,
    updateTaggerConfig,
    updatePlayerTag,
    updatePlayerTagByManual,
    updateTeamTag,
    updatePlayer,
    updateCoachTeam,
    updateUserEdit,
    addNewEditClips,
    updateEditClipsSort,
    updateEditClip,
    sendShareEmail,
    verifyShareId,
    editAcademy,
    updateUser,
    updateSubscription,
    updateUserXml,

    clearUserSession,

    deletePlayersInTeam,

    deleteEditClip,
    deleteGame,
    deleteTeamTag,
    deletePlayerTag,
    deleteTeam,
    deleteLeague,
    deletePlayer,
    deleteCorrection,
    deleteCoachTeam,
    deleteUserEdit,
    deleteUserFolder,
    moveEditClips,
    copyEditClips,
    moveFolderNewPosition,
    deleteRepresentative,
    deleteAcademy,
    deleteAcademyFromRepresentative,
    deleteTeamsFromAcademy,
    deleteHideGame,
    deleteTeamFromHideGame,
    deleteGameFromHideGame,
    deleteAcademyCoach,
    deleteUser,
    sendEmailToUser,
    deleteAllMultiple,
    deleteMultipleDataByIds,
    updatePlayerDetection,
    deleteXml,
    deleteVideo,
    getPlayersStatsGamebyGameStandAlone,
    getGoalkeepersStatsGamebyGameStandAlone
};

export default gameService;
