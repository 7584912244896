import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    Popover,
    Divider,
    Button,
    Snackbar
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import SearchIcon from '@mui/icons-material/SearchOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShareIcon from '@mui/icons-material/Share';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

import GameService from '../../../services/game.service';
import { ActionData, MenuProps } from '../components/common';
import { getComparator, stableSort } from '../components/utilities';
import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';
import PlayerEditDialog from './playerEditDialog';
import PlayerStatDialog from './status';
import { getPeriod } from '../games/tabs/overview/tagListItem';
import TeamStatsVideoPlayer from '../teams/tabs/stats/videoDialog';
import GameExportToEdits from '../games/tabs/overview/exportEdits';
import PlayersGamesDialog from './gamesDialog';
import SeasonContext from '../../../common/Context';
import { getFilteredArray } from '../../../helpers/coach';
import CryptoJS from 'crypto-js';
import CloseIcon from '@mui/icons-material/Close';

const headCells = [
    { id: 'total_player_games', title: 'Games' },
    { id: 'total_goal', title: 'Goals', action: 'Goals' },
    { id: 'total_passes', title: 'Passes', action: 'Passes' },
    { id: 'total_successful_passes', title: 'Passes Completed', action: 'PassesSuccess' },
    { id: 'total_shot', title: 'Shots', action: 'GoalKick' },
    { id: 'total_shot_on_target', title: 'Shots On Target', action: 'ShotOnTarget' },
    { id: 'total_crosses', title: 'Crosses', action: 'Cross' },
    { id: 'total_cross_successful', title: 'Crosses Completed', action: 'Cross' },
    { id: 'total_dribble', title: 'Dribbles', action: 'Dribble' },
    { id: 'total_turnover', title: 'Turnovers', action: 'Turnover' },
    { id: 'total_interception', title: 'Interceptions', action: 'Interception' },
    { id: 'total_blocked', title: 'Blocked', action: 'Blocked' },
    { id: 'total_clearance', title: 'Clearance', action: 'Clearance' },
    { id: 'total_tackle', title: 'Tackles', action: 'Tackle' },
    { id: 'total_fouls', title: 'Fouls Given', action: 'Foul' },
    //{ id: 'total_draw_fouls', title: 'Draw Fouls', action: 'DrawFouls' },
    { id: 'total_corner', title: 'Corners', action: 'Corner' },
    { id: 'total_free_kick', title: 'Free Kicks', action: 'FreeKick' }
];

const Players = ({ t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);
    const [state, setState] = useReducer((old, action) => ({ ...old, ...action }), {
        searchText: '',
        teamFilter: 'none'
    });

    const { user: currentUser } = useSelector((state) => state.auth);
    const { searchText, teamFilter } = state;

    const teamsList = useSelector((state) => state.coach.teamsList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const playersStatsAdvanceSummaryList = useSelector((state) => state.coach.playersStatsAdvanceSummaryList);

    const [seasonTeamsList, setSeasonTeamsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('total_player_games');
    const [playerStats, setPlayerStats] = useState([]);
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [editPlayer, setEditPlayer] = useState(null);
    const [statOpen, setStatOpen] = useState(false);
    const [playerStat, setPlayerStat] = useState(null);
    const [playData, setPlayData] = useState([]);
    const [videoOpen, setVideoOpen] = useState(false);
    const [gameList, setGameList] = useState([]);
    const [exportOpen, setExportOpen] = useState(false);
    const [playerGames, setPlayerGames] = useState([]);
    const [gamesOpen, setGamesOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuPopoverOpen = Boolean(menuAnchorEl);
    const menuPopoverId = menuPopoverOpen ? 'simple-popover' : undefined;
    const [showResultAlert, setShowResultAlert] = useState(false);

    const [isShowShareIcon, setIsShowShareIcon] = useState("");

    const handleRequestSort = (prop) => {
        const isAsc = orderBy === prop && order === 'desc';

        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(prop);
    };

    const handleShowMenu = (player) => (e) => {
        setPlayerStat(player);
        setMenuAnchorEl(e.currentTarget);
    };

    const handleDisplayVideo = (cell, player) => async (e) => {
        if (cell.title !== 'Games' && player[cell.id] !== undefined && player[cell.id] > 0) {
            let playerGameIds = [];
            const player_games = gamesList.filter((game) => game.season_id === seasonFilter.id && (game.home_team_id === player.team_id || game.away_team_id === player.team_id));
            setGameList(player_games);

            await GameService.getPlayersGames(seasonFilter.id, teamFilter === 'none' ? null : teamFilter.team_id, player.player_id, null).then((res) => {
                playerGameIds = res.map((item) => item.game_id);
            });

            await GameService.getGamePlayerTags(
                currentUser.id,
                player.team_id,
                `${player.player_id}`,
                playerGameIds.length === 0 ? null : playerGameIds.join(','),
                ActionData[cell.action].action_id,
                ActionData[cell.action].action_type_id,
                ActionData[cell.action].action_result_id,
                null,
                null,
                null,
                null,
                null
            ).then((res) => {
                setPlayData(
                    res.map((item) => {
                        return {
                            tag_id: item.id,
                            start_time: item.player_tag_start_time,
                            end_time: item.player_tag_end_time,
                            player_name: item.player_names,
                            action_name: item.action_names,
                            action_type: item.action_type_names,
                            action_result: item.action_result_names,
                            game_id: item.game_id,
                            team_id: player.team_id,
                            court_area: item.court_area_id,
                            inside_pain: item.inside_the_pain,
                            period: getPeriod(item.period),
                            time: item.time_in_game,
                            home_team_image: item.home_team_logo,
                            away_team_image: item.away_team_logo,
                            home_team_goals: item.home_team_goal,
                            away_team_goals: item.away_team_goal
                        };
                    })
                );
                setVideoOpen(true);
            });
        }
    };

    const handleExportPlayerTags = (cell, player) => async (e) => {
        e.preventDefault();

        if (cell.title !== 'Games' && player[cell.id] !== undefined && player[cell.id] > 0) {
            let playerGameIds = [];

            await GameService.getPlayersGames(seasonFilter.id, teamFilter === 'none' ? null : teamFilter.team_id, player.player_id, null).then((res) => {
                playerGameIds = res.map((item) => item.game_id);
            });
            await GameService.getGamePlayerTags(
                currentUser.id,
                player.team_id,
                `${player.player_id}`,
                playerGameIds.length === 0 ? null : playerGameIds.join(','),
                ActionData[cell.action].action_id,
                ActionData[cell.action].action_type_id,
                ActionData[cell.action].action_result_id,
                null,
                null,
                null,
                null,
                null
            ).then((res) => {
                setPlayData(res);
                setExportOpen(true);
            });
        }
    };

    const handleDisplayGames = async () => {
        let gameIds = [];

        setMenuAnchorEl(null);
        await GameService.getPlayersGames(seasonFilter.id, teamFilter === 'none' ? null : teamFilter.team_id, playerStat.player_id, null).then((res) => {
            gameIds = res.map((item) => item.game_id);
        });
        await GameService.getPlayersStatsGamebyGame({
            seasonId: seasonFilter.id,
            leagueId: null,
            gameId: gameIds.length === 0 ? null : gameIds.join(','),
            teamId: playerStat.team_id,
            playerId: playerStat.player_id,
            gameTime: null,
            courtAreaId: null,
            insidePaint: null,
            homeAway: null,
            gameResult: null
        }).then((res) => {
            setPlayerGames(stableSort(res, getComparator('desc', 'game_date')));
            setGamesOpen(true);
        });
    };

    const handleDisplayStats = () => {
        setMenuAnchorEl(null);
        const player_games = gamesList.filter((game) => game.season_id === seasonFilter.id && (game.home_team_id === playerStat.team_id || game.away_team_id === playerStat.team_id));
        setGameList(player_games);
        setStatOpen(true);
    };

    const handleChange = (prop) => (e) => {
        setState({ [prop]: e.target.value });
    };

    const compareStrings = (first, last) => {
        return first.toLowerCase().includes(last.toLowerCase());
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getPlayers = () => {
        return searchText
            ? playerStats.filter((item) => compareStrings(item.player_position_name, searchText) || compareStrings(item.player_full_name, searchText) || compareStrings(item.team_name, searchText))
            : teamFilter !== 'none'
                ? playerStats.filter((item) => item.team_id === teamFilter.team_id)
                : playerStats;
    };

    const getSortedArray = () => {
        return stableSort(getPlayers(), getComparator(order, orderBy));
    };

    useEffect(() => {
        if (!currentUser.can_share)
            setIsShowShareIcon("hidden");
        else
            setIsShowShareIcon("");
    }, []);
    useEffect(() => {
        (async () => {
            if (
                playersStatsAdvanceSummaryList &&
                playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name) &&
                playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].length
            )
                setLoading(false);
        })();
    }, [filteredPlayers]);

    useEffect(() => {
        (async () => {
            const sortedPlayers = getSortedArray();
            setFilteredPlayers(sortedPlayers);
        })();
    }, [playerStats, searchText, order, orderBy]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (playersStatsAdvanceSummaryList && playersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                const filtered_arr = getFilteredArray(playersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name], seasonFilter, null, null, teamFilter, null);
                setPlayerStats(filtered_arr);
            }
        })();
    }, [playersStatsAdvanceSummaryList, seasonFilter, teamFilter]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (teamsList.length) {
                const filteredTeams = teamsList.filter((team) => team.season_id === seasonFilter.id);
                setSeasonTeamsList(filteredTeams);
                setState({ teamFilter: 'none' });
            }
        })();
    }, [teamsList, seasonFilter]);

    const encryptId = (id) => {
        const secretKey = 'scouting4u';
        const encrypted = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
        const encoded = encodeURIComponent(encrypted); // Encode encrypted data for URL safety
        return encoded;
    };


    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(`${window.location.origin}/player/${encryptId(playerStat.player_id)}`)
            .then(() => {
                setShowResultAlert(true);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
        setMenuAnchorEl(null)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowResultAlert(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Box sx={{ width: '98%', height: '85vh', margin: '0 auto' }}>
            <Box sx={{ width: '100%', padding: '24px 24px 24px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className="page-title">{t('All Players')}</p>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <p className="select-narrator">{t('Team')}</p>
                        <Select
                            value={teamFilter}
                            onChange={handleChange('teamFilter')}
                            label=""
                            variant="outlined"
                            IconComponent={ExpandMoreIcon}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={MenuProps}
                            sx={{ borderRadius: '10px', outline: 'none', height: '36px', width: '300px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                        >
                            <MenuItem key="0" value="none">
                                {t('All')}
                            </MenuItem>
                            {seasonTeamsList &&
                                seasonTeamsList.map((team, index) => (
                                    <MenuItem key={index + 1} value={team}>
                                        {team.team_name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                    <TextField
                        value={searchText}
                        onChange={handleChange('searchText')}
                        placeholder={t('Search')}
                        label=""
                        inputProps={{ 'aria-label': 'Without label' }}
                        variant="outlined"
                        sx={{
                            width: '300px',
                            fontSize: '0.8rem',
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                            '& .MuiOutlinedInput-input': { padding: 0, height: '36px' }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        onMouseDown={handleMouseDownPassword}
                                        sx={{ backgroundColor: '#F8F8F8', '&:hover': { backgroundColor: '#F8F8F8' }, '&:focus': { backgroundColor: '#F8F8F8' } }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            </Box>

            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            <Snackbar
                open={showResultAlert}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Link copied to clipboard!"
                action={action}
            />

            {!loading && (
                <>
                    {playerStats.length > 0 && (
                        <Box sx={{ height: '85vh', marginLeft: '10px', background: '#ffffff' }}>
                            <TableContainer sx={{ maxHeight: '85vh' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow height="36px">
                                            <TableCell key="name" align="center" colSpan={2}>
                                                {t('Name')}
                                            </TableCell>
                                            <TableCell key="team" align="center">
                                                {t('Team')}
                                            </TableCell>
                                            {headCells.map((cell) => (
                                                <TableCell key={cell.id} align="center" sortDirection={orderBy === cell.id ? order : false}>
                                                    <TableSortLabel active={orderBy === cell.id} direction={orderBy === cell.id ? order : 'asc'} onClick={() => handleRequestSort(cell.id)}>
                                                        {t(cell.title)}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPlayers.map((player, index) => (
                                            <TableRow key={`${player.player_id}-${index}`} height="70px" hover>
                                                <TableCell key={`${player.player_id}-${index}-0`} width="5%" align="center" sx={{ cursor: 'pointer' }} onClick={handleShowMenu(player)}>
                                                    <img
                                                        style={{ height: '70px', borderRadius: '8px', paddingTop: '2px', paddingBottom: '2px' }}
                                                        src={
                                                            player.player_image
                                                                ? player.player_image.includes(process.env.REACT_APP_S3_URI)
                                                                    ? player.player_image
                                                                    : process.env.REACT_APP_S3_URI + player.player_image
                                                                : PLAYER_ICON_DEFAULT
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell key={`${player.player_id}-${index}-1`}>
                                                    <Box sx={{ paddingLeft: '3px', width: '155px', cursor: 'pointer' }} onClick={handleShowMenu(player)}>
                                                        <div style={{ alignItems: 'left', gap: '8px' }}>
                                                            <p className="bold-text">{player?.player_full_name}</p>
                                                        </div>
                                                        <div style={{ alignItems: 'left', gap: '8px' }}>
                                                            <p className="normal-text">#{player?.player_jersey_number < 999 ? player?.player_jersey_number : 0} {player?.player_position_name ?? ''}</p>
                                                        </div>

                                                    </Box>
                                                </TableCell>
                                                <TableCell key={`${player.player_id}-${index}-2`} align="center">
                                                    {player?.team_name ?? ''}
                                                </TableCell>
                                                {headCells.map((cell, cId) => (
                                                    <TableCell
                                                        key={`${cell.id}-${index}-${cId}`}
                                                        align="center"
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={handleDisplayVideo(cell, player)}
                                                        onContextMenu={handleExportPlayerTags(cell, player)}
                                                    >
                                                        {cell.id === 'total_saved' ? player[cell.id] + player['total_super_save'] : player[cell.id]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PlayerEditDialog t={t} open={editOpen} onClose={() => setEditOpen(false)} player={editPlayer} page="players" />
                            <PlayerStatDialog
                                t={t}
                                open={statOpen}
                                onClose={() => setStatOpen(false)}
                                player={playerStat}
                                games={gameList}
                                gameIds={gameList.map((item) => item.id)}
                                initialState={playerStat}
                            />
                            <TeamStatsVideoPlayer t={t} open={videoOpen} onClose={() => setVideoOpen(false)} video_url={gameList} tagList={playData} />
                            <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
                            <PlayersGamesDialog t={t} open={gamesOpen} onClose={() => setGamesOpen(false)} list={playerGames} playerName={playerStat?.player_full_name ?? ''} />
                            <Popover
                                id={menuPopoverId}
                                open={menuPopoverOpen}
                                anchorEl={menuAnchorEl}
                                onClose={() => setMenuAnchorEl(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                sx={{ '& .MuiPopover-paper': { width: '220px', borderRadius: '12px', border: '1px solid #E8E8E8' } }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleDisplayStats()}>
                                    <QueryStatsIcon />
                                    <p className="menu-item">{t('Accumulated Stats')}</p>
                                </Box>
                                <Divider sx={{ width: '100%' }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleDisplayGames()}>
                                    <SportsSoccerIcon />
                                    <p className="menu-item">{t('Game By Game')}</p>
                                </Box>
                                <Divider sx={{ width: '100%' }} />
                                <Box
                                    sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setMenuAnchorEl(null);
                                        setEditPlayer(playerStat);
                                        setEditOpen(true);
                                    }}
                                >
                                    <EditIcon />
                                    <p className="menu-item">{t('Edit Player')}</p>
                                </Box>
                                <Divider sx={{ width: '100%' }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleCopyLink()} hidden={isShowShareIcon}>
                                    <ShareIcon />
                                    <p className="menu-item">{t('Share')}</p>
                                </Box>
                            </Popover>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default Players;
