// import React from 'react';
import builder from 'xmlbuilder';
// import fileDownload from 'js-file-download';

import gameService from '../../../services/game.service';
import { TEAM_ICON_DEFAULT } from '../../../common/staticData';
import { getPeriod } from '../games/tabs/overview/tagListItem';
import * as settings from '../../../config/settings';
// import Alert from '../../../common/alert';
import authService from '../../../services/auth.service';
import { getUser } from '../../../common/utilities';
const fetch = require('node-fetch');

export const processXml = (xmlData, xml_id, name) => {
    //const new_name = Date.now() + '_' + name + '.mp4';
    const serverUrl = `${settings.xmlServer}/`;

    // Create a new Headers object
    var headers = new Headers();
    headers.append('Content-Type', 'text/xml');
    headers.append('New-Name', xml_id); // Add new_name to the headers
    headers.append('api-server', 'geniusballapi');

    // Create the request options
    var requestOptions = {
        method: 'POST',
        headers: headers,
        body: xmlData,
        redirect: 'follow'
    };

    function fetchWithTimeout(url, options, timeout = 1000000) {
        return Promise.race([
            fetch(url, options).catch((error) => {
                console.error('Fetch error:', error);
                throw error;
            }),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), timeout))
        ]);
    }

    const API_URL = `${settings.APIBASEURL}/`;
    const user = getUser();

    let currentDateTime = new Date().toISOString();
    gameService
        .updateUserXml({
            id: xml_id,
            status: 4,
            date_time: currentDateTime,
            video_name: '',
            download_link: ''
        })
        .then((res) => {
            if (user) {
                fetch(API_URL + `get-rendering-ids/${user.id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        const updatedRenderingIds = [...data, xml_id];
                        return fetch(API_URL + `update-rendering-ids/${user.id}`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ renderingIds: updatedRenderingIds })
                        });
                    })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('HTTP error ' + response.status);
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to update renderingIds. ' + err);
                    });
            } else {
                console.error('User is not logged in');
            }
        })
        .catch((err) => {
            console.log('failed to update xml table before rendering', err);
        });

    // Make the request
    return new Promise((resolve, reject) => {
        fetchWithTimeout(serverUrl, requestOptions, 1000000)
            .then((response) => response.text())
            .then((result) => {
                result = result.replace(/^\s*\n/gm, '');
                var download_link = result;

                console.log('RESULT === ',result)
                
                if (download_link.substring(0, 11) === 'https://eu2') {
                    const parts = download_link.split('/');
                    var lastPart = parts[parts.length - 1];
                    lastPart = lastPart.replace(/\s/g, '');
                    if (lastPart === '') {
                    }

                    // let currentDateTime = new Date().toISOString();
                    // gameService.updateUserXml({
                    //     id: xml_id,
                    //     status: 2,
                    //     date_time: currentDateTime,
                    //     video_name: lastPart,
                    //     download_link: download_link,
                    // })
                    // .then((res) =>{
                    //     console.log("response from updating xml table after rendering", res)
                    // })
                    // .catch(err =>{
                    //     console.log("failed to update xml table after rendering", err)
                    //     reject("failed");
                    // })

                    fetch(download_link)
                        .then((response) => response.blob())
                        .then((blob) => {
                            // Create an object URL for the blob
                            const url = URL.createObjectURL(blob);
                            // Create a new anchor element
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want

                            a.download = lastPart;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            resolve('downloaded');
                        })
                        .catch(() => {
                            resolve('failed');
                        });
                } else {
                    gameService.getUserXml(xml_id).then((res) => {
                        let user_id = res.user_id;
                        let editname = res.name;
                        authService.sendFailedEmail(xml_id, user_id, editname).then((res) => {});
                    });

                    reject('failed');
                }
            })
            .catch((error) => {
                reject('Check Render tab later');
                // return error
            });
    });
};

export const gameCreateCommand = async (tagList, name, games, gameIds) => {
    let videoList = await Promise.all(
        games.map(async (game) => {
            if (game.video_url?.includes('youtube.com') || game.video_url?.includes('youtu.be')) {
                const newUrl = (await gameService.getAsyncNewStreamURL(game.video_url)).video_url;

                return { url: newUrl, home: game.home_team_image, away: game.away_team_image };
            }

            return { url: game.video_url, home: game.home_team_image, away: game.away_team_image };
        })
    );

    let videos = videoList.map((tag) => {
        return {
            url: tag.url,
            SecondBoxText: name.replace("'", ''),
            HomeTeamLogo: tag.home ? tag.home : TEAM_ICON_DEFAULT,
            AwayTeamLogo: tag.away ? tag.away : TEAM_ICON_DEFAULT
        };
    });

    let clips = tagList.map((tag) => {
        const period = tag.period === 1 ? 'H1' : tag.period === 2 ? 'H2' : 'OT';

        return {
            Video: gameIds.indexOf(tag.game_id ?? gameIds) + 1,
            Trim: `${toSecond(tag.team_tag_start_time)}:${toSecond(tag.team_tag_end_time)}`,
            GameTime: `${period} ${tag.time_in_game ?? tag.game_time}'`,
            GameScore: `${tag.home_team_goal} - ${tag.away_team_goal}`,
            FirstBoxText: `${tag.player_names ?? tag.started_player_name} - ${tag.action_names ?? tag.started_action_name} - ${tag.action_type_names ?? tag.started_action_type_name} - ${
                tag.action_result_names ?? tag.started_action_result_name
            }`
        };
    });

    let obj = {
        Render: {
            FileData: {
                Name: name.replace("'", ''),
                Format: 'mp4',
                Resolution: '1280x720',
                FPS: '60',
                Preset: 'ultrafast',
                FontFile: 'ArialBold.ttf',
                FontColor: '#ffffff',
                FontSize: '35',
                FirstBoxSize: '300x60',
                FirstBoxColor: '#808080@0.7',
                FirstBoxFormat: 'rgba',
                SeconBoxSize: '500x60',
                SecondBoxColor: '#FFA500@0.7',
                SecondBoxFormat: 'rgba',
                LogoURL: 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png'
            },
            Videos: { Video: videos },
            Clips: { Clip: clips }
        }
    };

    const command = builder.create(obj).end({ pretty: true });

    let currentDateTime = new Date().toISOString();

    return new Promise((resolve, reject) => {
        gameService
            .createUserXml({ name: name, xml_txt: command, date_time: currentDateTime })
            .then((res) => {
                var xml_id = res['id'];
                processXml(command, xml_id, name)
                    .then((download_link) => {
                        resolve(download_link);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((err) => {
                reject(err);
            });
    });

    // fileDownload(command, `${name}.xml`);
};

export const gamePlayerCreateCommand = async (tagList, name, games, gameIds) => {
    let videoList = await Promise.all(
        games.map(async (game) => {
            if (game.video_url?.includes('youtube.com') || game.video_url?.includes('youtu.be')) {
                const newUrl = (await gameService.getAsyncNewStreamURL(game.video_url)).video_url;

                return { url: newUrl, home: game.home_team_image, away: game.away_team_image };
            }

            return { url: game.video_url, home: game.home_team_image, away: game.away_team_image };
        })
    );

    let videos = videoList.map((tag) => {
        return {
            url: tag.url,
            SecondBoxText: name.replace("'", ''),
            HomeTeamLogo: tag.home ? tag.home : TEAM_ICON_DEFAULT,
            AwayTeamLogo: tag.away ? tag.away : TEAM_ICON_DEFAULT
        };
    });

    let clips = tagList.map((tag) => {
        const period = tag.period === 1 ? 'H1' : tag.period === 2 ? 'H2' : 'OT';

        return {
            Video: gameIds.indexOf(tag.game_id) + 1,
            Trim: `${toSecond(tag.player_tag_start_time)}:${toSecond(tag.player_tag_end_time)}`,
            GameTime: `${period} ${tag.time_in_game}'`,
            GameScore: `${tag.home_team_goal} - ${tag.away_team_goal}`,
            FirstBoxText: `${tag.player_names} - ${tag.action_names} - ${tag.action_type_names} - ${tag.action_result_names}`
        };
    });

    let obj = {
        Render: {
            FileData: {
                Name: name.replace("'", ''),
                Format: 'mp4',
                Resolution: '1280x720',
                FPS: '60',
                Preset: 'ultrafast',
                FontFile: 'ArialBold.ttf',
                FontColor: '#ffffff',
                FontSize: '35',
                FirstBoxSize: '300x60',
                FirstBoxColor: '#808080@0.7',
                FirstBoxFormat: 'rgba',
                SeconBoxSize: '500x60',
                SecondBoxColor: '#FFA500@0.7',
                SecondBoxFormat: 'rgba',
                LogoURL: 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png'
            },
            Videos: { Video: videos },
            Clips: { Clip: clips }
        }
    };

    const command = builder.create(obj).end({ pretty: true });

    let currentDateTime = new Date().toISOString();

    return new Promise((resolve, reject) => {
        gameService
            .createUserXml({ name: name, xml_txt: command, date_time: currentDateTime })
            .then((res) => {
                var xml_id = res['id'];
                processXml(command, xml_id, name)
                    .then((download_link) => {
                        resolve(download_link);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((err) => {
                reject(err);
            });
    });

    // fileDownload(command, `${name}.xml`);
};

export const editCreateCommand = async (tagList, name) => {
    let rawVideoList = [...new Set(tagList.map((tag) => tag.video_url))];
    let videoList = [];

    await Promise.all(
        tagList.map(async (game) => {
            let newUrl = '';

            if (game.video_url?.includes('youtube.com') || game.video_url?.includes('youtu.be')) newUrl = (await gameService.getAsyncNewStreamURL(game.video_url)).video_url;
            else newUrl = game.video_url;

            const duplicate = videoList.filter((item) => item.url === newUrl);

            if (duplicate.length === 0) videoList = [...videoList, { url: newUrl, home: game.home_team_logo, away: game.away_team_logo }];

            return videoList;
        })
    );

    let videos = videoList.map((tag) => {
        return {
            url: tag.url,
            SecondBoxText: name,
            HomeTeamLogo: tag.home ? tag.home : TEAM_ICON_DEFAULT,
            AwayTeamLogo: tag.away ? tag.away : TEAM_ICON_DEFAULT
        };
    });

    let clips = tagList.map((tag) => {
        return {
            Video: rawVideoList.indexOf(tag.video_url) + 1,
            Trim: `${toSecond(tag.start_time)}:${toSecond(tag.end_time)}`,
            GameTime: `${getPeriod(tag.period)} ${tag.time_in_game}'`,
            GameScore: `${tag.home_team_goal} - ${tag.away_team_goal}`,
            FirstBoxText: tag.clip_name
        };
    });

    let obj = {
        Render: {
            FileData: {
                Name: name,
                Format: 'mp4',
                Resolution: '1280x720',
                FPS: '60',
                Preset: 'ultrafast',
                FontFile: 'ArialBold.ttf',
                FontColor: '#ffffff',
                FontSize: '35',
                FirstBoxSize: '300x60',
                FirstBoxColor: '#808080@0.7',
                FirstBoxFormat: 'rgba',
                SeconBoxSize: '500x60',
                SecondBoxColor: '#FFA500@0.7',
                SecondBoxFormat: 'rgba',
                LogoURL: 'https://s4usitesimages.s3.amazonaws.com/images/JustSmallLogo.png'
            },
            Videos: { Video: videos },
            Clips: { Clip: clips }
        }
    };

    const command = builder.create(obj).end({ pretty: true });
    let currentDateTime = new Date().toISOString();

    return new Promise((resolve, reject) => {
        gameService
            .createUserXml({ name: name, xml_txt: command, date_time: currentDateTime })
            .then((res) => {
                var xml_id = res['id'];
                processXml(command, xml_id, name)
                    .then((download_link) => {
                        resolve(download_link);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const downloadJsonFile = (data, setRelease) => {
    let filename = 'game.json';
    let contentType = 'application/json;charset=utf-8;';

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(data)))], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(data));
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    setRelease(false);
};

export function toSecond(data) {
    if (!data || data === '') return 0;
    let a = data.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;

    if (b[orderBy] > a[orderBy]) return 1;

    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);

        if (order !== 0) return order;

        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

export function orderedSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        return a[0].order - b[0].order;
    });

    return stabilizedThis.map((el) => el[0]);
}

export function getFormattedDate(date) {
    const old_format = date.match(/\d\d\d\d-\d\d-\d\d/) + '';
    const array = old_format.split('-');

    return `${array[2]}/${array[1]}/${array[0]}`;
}
